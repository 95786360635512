import React from 'react'
import { externalLink } from './externalLink.module.scss'

export default function ExternalLink({ children, className, ...props }) {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      className={`${externalLink} ${className || ''}`}
      {...props}
    >
      {children}
      <svg x="0px" y="0px" viewBox="0 0 448 512">
        <path
          fill="currentColor"
          d="M66.6,146l0-31.4c0-13.3,10.7-24,24-24l274.9-0.1c13.3,0,24,10.7,24,24v274.8c0,13.3-10.7,24-24,24l-31.4,0
	c-13.4,0-24.3-11.1-24-24.5l4-166.2L110.8,425.7c-9.4,9.4-24.5,9.4-33.9,0l-22.6-22.6c-9.4-9.4-9.4-24.5,0-33.9l203.2-203.2
	l-166.4,4C77.6,170.3,66.5,159.5,66.6,146z"
        />
      </svg>
    </a>
  )
}
