import { useEffect, useRef } from 'react'

export default function useOnClickOutside(callback) {
  const node = useRef()

  useEffect(() => {
    const handleClick = e => {
      if (node.current?.contains(e.target)) {
        return
      }

      callback()
    }

    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [callback])

  return node
}
