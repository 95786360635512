import React, { useState } from 'react'
import { formInput, filled, prefix, invalid } from './textInput.module.scss'

export default function TextInput(props) {
  const [isInvalid, setIsInvalid] = useState(false)

  return (
    <div className={formInput}>
      {props.prefix ? <label>{props.prefix}</label> : null}
      <input
        id={props.placeholder}
        type={props.type || 'text'}
        className={`${props.value ? filled : ''} ${
          props.prefix ? prefix : ''
        } ${isInvalid ? invalid : ''}`}
        value={props.value}
        onChange={props.onChange}
        required={props.required}
        onInvalid={() => setIsInvalid(true)}
        onInput={() => setIsInvalid(false)}
        aria-label={props.ariaLabel}
      />
      {props.placeholder ? (
        <label htmlFor={props.placeholder}>{`${props.placeholder}${
          props.required ? '*' : ''
        }`}</label>
      ) : null}
    </div>
  )
}
