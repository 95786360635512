import React, { useState } from 'react'
import logoSVG from '../../../images/qnips-logo-white.svg'
import Menu from '../menu/menu'
import { header, hamburg, checked, line, logo } from './header.module.scss'
import LanguageSelector from './language-selector/languageSelector'
import { LocalizedLink } from '../../generic/link/link'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import useContent from '../../../hooks/useContent'

export default function Navigation(props) {
  const [menuOpen, setMenuOpen] = useState(false)
  const navigationRef = useOnClickOutside(() => setMenuOpen(false))

  const { navigation } = useContent()

  return (
    <header
      className={`${header} d-flex flex-row justify-content-between align-items-center p-3`}
      ref={navigationRef}
    >
      <LocalizedLink className={logo} to="/">
        <img className="p-2" src={logoSVG} alt="Logo" />
      </LocalizedLink>
      <div className="d-flex flex-row align-items-center">
        <LanguageSelector />
        <button
          // todo: add checked class if menu is open
          className={`${hamburg} ${menuOpen ? checked : ''}`}
          onClick={() => setMenuOpen(!menuOpen)}
          aria-label={navigation?.menu}
        >
          <span className={line}></span>
          <span className={line}></span>
          <span className={line}></span>
        </button>
      </div>

      <Menu open={menuOpen} closeMenu={() => setMenuOpen(false)} />
    </header>
  )
}
