import React from 'react'
import { createContext } from 'react'

export const ContentContext = createContext()

export default function ContentProvider({ content, children }) {
  return (
    <ContentContext.Provider value={{ navigation: { lang: 'en' }, ...content }}>
      {children}
    </ContentContext.Provider>
  )
}
