import React, { createContext, useState } from 'react'
import { InternorgaInvitation } from '../../../generic/internorga-invitation/internorgaInvitation'
import Dialog from '../dialog/dialog'
import { modalContainer, open } from './modalContainer.module.scss'

export const ModalContext = createContext({
  setModal: () => {},
  modalOpen: false,
})

export default function ModalContainer(props) {
  const [modal, setModal] = useState(undefined)

  return (
    <ModalContext.Provider value={{ setModal, modalOpen: modal }}>
      <div className={`${modalContainer} ${modal ? open : null}`}>
        {props.children}
      </div>
      <Dialog
        show={modal != null}
        onHide={() => setModal(undefined)}
        alignTopOnMobile={modal?.alignTopOnMobile}
      >
        {modal?.component}
      </Dialog>
    </ModalContext.Provider>
  )
}
