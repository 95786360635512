import React from 'react'
import { socialMedia } from './socialMedia.module.scss'
import xingIcon from '../../../images/social-media/SM_XING.svg'
import instagramIcon from '../../../images/social-media/SM_Instagram.svg'
import facebookIcon from '../../../images/social-media/SM_Facebook.svg'
import linkedinIcon from '../../../images/social-media/SM_LinkedIn.svg'

export default function SocialMedia(props) {
  return (
    <div className={`d-flex flex-row mb-4 mb-sm-0 ${socialMedia}`}>
      <a
        href="https://www.instagram.com/qnips.gmbh/?hl=de"
        target="_blank"
        rel="noreferrer"
      >
        <img src={instagramIcon} alt="Instagram" height={25}></img>
      </a>
      <a
        href="https://www.facebook.com/qnips/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={facebookIcon} alt="Facebook" height={25}></img>
      </a>
      <a
        href="https://www.linkedin.com/company/qnips-gmbh/?originalSubdomain=de"
        target="_blank"
        rel="noreferrer"
      >
        <img src={linkedinIcon} alt="LinkedIn" height={25}></img>
      </a>
      <a
        href="https://www.xing.com/companies/qnipsgmbh"
        target="_blank"
        rel="noreferrer"
      >
        <img src={xingIcon} alt="Xing" height={25}></img>
      </a>
    </div>
  )
}
