import React from 'react'
import useContent from '../../../hooks/useContent'
import { Link } from 'gatsby'

export function LocalizedLink({ to, ...props }) {
  const { navigation } = useContent()

  return (
    <Link
      {...props}
      to={navigation.lang === 'en' ? to : `/${navigation.lang}${to}`}
    />
  )
}
