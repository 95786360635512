import React, { useState } from 'react'
import { textArea, invalid, inquiryLabel } from './textArea.module.scss'

export default function TextArea({ label, ...textAreaProps }) {
  const [isInvalid, setIsInvalid] = useState(false)

  return (
    <div className="d-flex flex-column">
      <textarea
        {...textAreaProps}
        id={label}
        className={`${textArea} ${isInvalid ? invalid : ''} order-2`}
        onInvalid={() => setIsInvalid(true)}
        onInput={() => setIsInvalid(false)}
      />
      {label != null ? (
        <label
          htmlFor={label}
          className={`${inquiryLabel} ${isInvalid ? invalid : ''} mt-3`}
        >
          {label}
        </label>
      ) : null}
    </div>
  )
}
