import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  languageSelector,
  currentLocale,
  active,
  icon,
  openIcon,
  dropdown,
} from './languageSelector.module.scss'
import { Fade } from 'react-bootstrap'
import useContent from '../../../../hooks/useContent'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import useOnClickOutside from '../../../../hooks/useOnClickOutside'

const languages = {
  en: 'EN',
  de: 'DE',
}

export default function LanguageSelector(props) {
  const { navigation } = useContent()
  const location = useLocation()
  const initialRender = useRef(true)

  const [open, setOpen] = useState(false)
  const languageSelectorRef = useOnClickOutside(() => setOpen(false))

  const changeLocale = useCallback(
    locale => {
      let path = location.pathname.split('/')
      if (navigation.lang !== 'en') {
        path.splice(1, 1)
      }
      path = path.join('/')
      if (locale !== 'en') {
        path = '/' + locale + path
      }

      navigate(path)
      setOpen(false)
    },
    [location.pathname, navigation.lang]
  )

  useEffect(() => {
    if (!initialRender.current) {
      return
    }

    const browserLanguage = navigator?.language?.split('-')[0]

    if (Object.keys(languages).includes(browserLanguage)) {
      changeLocale(browserLanguage)
    }
    initialRender.current = false
  }, [changeLocale])

  return (
    <div
      className={`${languageSelector} d-flex flex-row align-items-center mr-3`}
      ref={languageSelectorRef}
    >
      <button
        onClick={() => setOpen(!open)}
        className="d-flex flex-row align-items-baseline"
      >
        <div className={`${currentLocale} ${open ? active : null}`}>
          {languages[navigation.lang]}
        </div>
        {open ? (
          <div className={icon}>&#x2715;</div>
        ) : (
          <div className={`${icon} ${openIcon}`}>
            <span></span>
          </div>
        )}
      </button>
      <Fade in={open}>
        <ul className={dropdown}>
          {Object.keys(languages)
            .filter(locale => locale !== navigation.lang)
            .map(locale => (
              <li className="mt-2" key={locale}>
                <button onClick={() => changeLocale(locale)}>
                  {languages[locale]}
                </button>
              </li>
            ))}
        </ul>
      </Fade>
    </div>
  )
}
