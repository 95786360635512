import React, { useContext } from 'react'
import { Fade } from 'react-bootstrap'
import SocialMedia from '../../generic/social-media/socialMedia'
import { menu, menuContent, mail, activeLink, phone } from './menu.module.scss'
import { LocalizedLink } from '../../generic/link/link'
import { ModalContext } from '../modal/modal-container/modalContainer'
import useContent from '../../../hooks/useContent'
import ExternalLink from '../../generic/external-link/externalLink'
import ContactForm from '../../generic/contact-form/contactForm'

const InternalLink = ({ children, ...props }) => (
  <LocalizedLink {...props} activeClassName={activeLink}>
    {children}
  </LocalizedLink>
)

const ListItem = ({ children }) => <li className="mb-2">{children}</li>

export default function Menu({ open, closeMenu }) {
  const { setModal } = useContext(ModalContext)
  const { navigation } = useContent()

  return (
    <Fade in={open} mountOnEnter={true} unmountOnExit={true}>
      <div className={`${menu}`}>
        <div className={`${menuContent}`}>
          <ul>
            <ListItem>
              <InternalLink to="/" onClick={closeMenu}>
                {navigation.home}
              </InternalLink>
            </ListItem>
            <ListItem>
              <InternalLink
                to="/features"
                onClick={closeMenu}
                partiallyActive={true}
              >
                {navigation.features}
              </InternalLink>
            </ListItem>
            <ListItem>
              <InternalLink to="/partners-and-interfaces" onClick={closeMenu}>
                {navigation.partners}
              </InternalLink>
            </ListItem>
            <ListItem>
              <InternalLink to="/about-us" onClick={closeMenu}>
                {navigation.aboutUs}
              </InternalLink>
            </ListItem>

            <ListItem>
              <InternalLink to="/sustainability" onClick={closeMenu}>
                {navigation.sustainability}
              </InternalLink>
            </ListItem>
            <ListItem>
              <ExternalLink href="https://blog.qnips.io/">
                {navigation.blog}
              </ExternalLink>
            </ListItem>
            <ListItem>
              <InternalLink to="/jobs" onClick={closeMenu}>
                {navigation.jobs}
              </InternalLink>
            </ListItem>
            <ListItem>
              <button
                className="btn btn-link"
                onClick={() =>
                  setModal({
                    component: <ContactForm />,
                    alignTopOnMobile: true,
                  })
                }
              >
                {navigation.contact}
              </button>
            </ListItem>
          </ul>
          <div className="my-5">
            <div>
              <a href={'mailto:' + navigation.mail} className={mail}>
                {navigation.mail}
              </a>
            </div>
            <a href={'tel:' + navigation.phoneNumber} className={phone}>
              {navigation.phoneNumber}
            </a>
          </div>
          <SocialMedia />
        </div>
      </div>
    </Fade>
  )
}
