import React from 'react'
import useIsMobile from '../../../../hooks/useIsMobile'
import { accordionItem, accordionToggle } from './accordionItem.module.scss'

export default function FooterAccordionItem({ children, isOpen, title, open }) {
  const isMobile = useIsMobile()

  return (
    <div
      className={`col-12 col-md d-flex flex-column align-items-center align-items-md-start ${accordionItem}`}
    >
      <div>
        <button className={accordionToggle} disabled={!isMobile} onClick={open}>
          <h2 className="h5 mb-0 mb-md-2">{title}</h2>
        </button>
      </div>
      <div
        className={`${
          !isOpen ? 'd-none' : 'd-flex'
        } mb-0 d-md-flex flex-column align-items-center align-items-md-start`}
      >
        {children}
      </div>
    </div>
  )
}
