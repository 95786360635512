import React, { useContext, useState } from 'react'
import logo from '../../../images/qnips-logo-white.svg'
import { footer, phone, mail } from './footer.module.scss'
import FooterAccordionItem from './accordion-item/accordionItem'
import SocialMedia from '../../generic/social-media/socialMedia'
import { LocalizedLink } from '../../generic/link/link'
import { ModalContext } from '../modal/modal-container/modalContainer'
import useContent from '../../../hooks/useContent'
import ExternalLink from '../../generic/external-link/externalLink'
import ContactForm from '../../generic/contact-form/contactForm'

export default function Footer(props) {
  const [openId, setOpenId] = useState(0)
  const { setModal } = useContext(ModalContext)
  const { navigation } = useContent()

  return (
    <footer className={footer}>
      <div className="container py-5">
        <div className="row d-none d-md-block mb-5">
          <div className="col d-flex">
            <LocalizedLink className="d-block" to="/">
              <img src={logo} alt="Logo" width={80} />
            </LocalizedLink>
          </div>
        </div>
        <div className="row mb-4 mb-md-5">
          <div className="col-12 col-md text-center text-md-left">
            <div
              className="mb-4"
              dangerouslySetInnerHTML={{
                __html: navigation.qnipsAddressHtml,
              }}
            ></div>
            <div className="mb-2 mb-md-0">
              <a href={'tel:' + navigation.phoneNumber} className={phone}>
                {navigation.phoneNumber}
              </a>
            </div>
            <div className="mb-4 mb-md-0">
              <a href={'mailto:' + navigation.mail} className={mail}>
                {navigation.mail}
              </a>
            </div>
          </div>
          <FooterAccordionItem
            isOpen={openId === 0}
            title={navigation.legalLinks}
            open={() => setOpenId(0)}
          >
            <div>
              <LocalizedLink to="/terms-of-use">
                {navigation.termsOfUse}
              </LocalizedLink>
            </div>
            <div>
              <LocalizedLink to="/data-protection">
                {navigation.dataProtection}
              </LocalizedLink>
            </div>
            <div>
              <LocalizedLink to="/legal-notice">
                {navigation.legalNotice}
              </LocalizedLink>
            </div>
            <div>
              <button
                className="btn btn-link"
                onClick={() => window.CCM.openWidget()}
              >
                {navigation.cookieSettings}
              </button>
            </div>
          </FooterAccordionItem>
          <FooterAccordionItem
            isOpen={openId === 1}
            title={navigation.company}
            open={() => setOpenId(1)}
          >
            <div>
              <LocalizedLink to="/about-us">{navigation.aboutUs}</LocalizedLink>
            </div>
            <div>
              <LocalizedLink to="/jobs">{navigation.jobs}</LocalizedLink>
            </div>
            <div>
              <button
                className="btn btn-link"
                onClick={() =>
                  setModal({
                    component: <ContactForm />,
                    alignTopOnMobile: true,
                  })
                }
              >
                {navigation.contact}
              </button>
            </div>
          </FooterAccordionItem>
          <FooterAccordionItem
            isOpen={openId === 2}
            title={navigation.services}
            open={() => setOpenId(2)}
          >
            <div>
              <ExternalLink href="https://blog.qnips.io/">
                {navigation.blog}
              </ExternalLink>
            </div>
            <div>
              <ExternalLink href="https://my.qnips.io">
                {navigation.dashboardLogin}
              </ExternalLink>
            </div>
          </FooterAccordionItem>
        </div>
        <div className="row">
          <div className="col d-flex flex-column-reverse flex-md-row align-items-center align-items-md-end justify-content-md-between">
            <div>&#169; qnips GmbH. All rights reserved.</div>
            <SocialMedia />
          </div>
        </div>
      </div>
    </footer>
  )
}
