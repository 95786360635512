import React from 'react'
import Header from './header/header'
import Footer from './footer/footer'
// import BgImage from './bgimage'
import {
  layout,
  overflowHidden,
  noiseContainer,
  contentContainer,
  content,
} from './layout.module.scss'
import { ParallaxProvider } from 'react-scroll-parallax'
import ModalContainer from './modal/modal-container/modalContainer'
import ContentProvider from './content-provider/contentProvider'

export default function Layout({ data, children, location }) {
  if (location.pathname.includes('/offline-plugin-app-shell-fallback'))
    return null

  const onSustainabilityPage = location.pathname.includes('/sustainability')

  return (
    <ParallaxProvider>
      <ContentProvider content={data}>
        <div
          className={`${layout} ${!onSustainabilityPage ? overflowHidden : ''}`}
        >
          <div className={noiseContainer}></div>
          <ModalContainer>
            <div className={contentContainer}>
              <Header />
              <div className={content}>{children}</div>
              <Footer></Footer>
            </div>
          </ModalContainer>
        </div>
      </ContentProvider>
    </ParallaxProvider>
  )
}
