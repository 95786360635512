import React from 'react'
import { Modal } from 'react-bootstrap'
import {
  container,
  backdrop,
  innerBackdrop,
  closeButton,
  alignTopOnMobile,
} from './dialog.module.scss'

export default function Dialog(props) {
  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      dialogAs="div"
      className="d-flex p-0"
      dialogClassName={`${container} ${
        props.alignTopOnMobile ? alignTopOnMobile : ''
      }`}
      backdropClassName={backdrop}
    >
      <div
        className={innerBackdrop}
        role="presentation"
        onClick={props.onHide}
      ></div>
      <button className={closeButton} onClick={props.onHide}>
        &#x2715;
      </button>
      {props.children}
    </Modal>
  )
}
