import { useLayoutEffect, useState } from 'react'

const breakpoint = 768

export default function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false)

  useLayoutEffect(() => {
    const updateIsMobile = () => setIsMobile(window.innerWidth < breakpoint)

    window.addEventListener('resize', updateIsMobile)
    updateIsMobile()
    return () => window.removeEventListener('resize', updateIsMobile)
  }, [])

  return isMobile
}
